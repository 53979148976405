import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
// vue-cookies配置
import VueCookies from 'vue-cookies'
import { sendSock, createWebSocket, closeSock, websock } from '@/utils/websocket'

const Login = () => import('../views/login/Login')
const Index = () => import('../views/index/Index')
const Home = () => import('../views/home/Home')
const HomePage = () => import('../views/home/childComps/HomePage')

const OrganApply = () => import('../views/organ/OrganApply')
const AddOrgan = () => import('../views/organ/AddOrgan')
const OrganInformation = () => import('../views/organ/OrganInformation')
const OrganAdministrator = () => import('../views/organ/OrganAdministrator')
const OrganList = () => import('../views/organ/OrganList')
const OrgExclusive = () => import('../views/organ/exclusive/Home')

const CustomMadeCreate = () => import('../views/activity/custom_made/CreateCustomView')
const ActivityType = () => import('../views/activity/ActivityType')
const ActivityList = () => import('../views/activity/ActivityList')
const AccessingDataList = () => import('../views/activity/childComps/AccessingDataList')
const ActivityConstruction = () => import('../views/activity/FormLayout')
const ActivityJoinList = () => import('../views/activity/ActivityJoinList')

const EnrollDataList = () => import('../views/activity/childComps/EnrollDataList')
const QaActEnrollDataList = () => import('../views/activity/childComps/QaActEnrollDataList')
const ActOrgDataList = () => import('../views/activity/childComps/ActOrgDataList')
const ClockRecordList = () => import('../views/activity/childComps/ClockRecordList')
const LookClockRecord = () => import('../views/activity/childComps/LookClockRecord')
const VoteDataList = () => import('../views/activity/childComps/VoteDataList')
const QaDataList = () => import('../views/activity/childComps/QaDataList')
const AnswerDataList = () => import('../views/activity/childComps/AnswerDataList')
const SubjectiveGrade = () => import('../views/activity/childComps/SubjectiveGrade')
const QaDetail = () => import('../views/activity/childComps/QaDetail')
const WorksDataList = () => import('../views/activity/childComps/WorksDataList')
const CollectWorkDetail = () => import('../views/activity/childComps/CollectWorkDetail')

// 资源管理
const CourseResourcesList = () => import('../views/resources/CourseResourcesList')
const AddOrEditResources = () => import('../views/resources/childComps/AddOrEditResources')
const PatchImportResources = () => import('../views/resources/childComps/PatchImportResources')
const OrgRankSetting = () => import('../views/org_rank_setting/Index')

// 数据管理
const ActivityDataList = () => import('../views/data/ActivityDataList')
const UserDataList = () => import('../views/data/UserDataList')
const ChannelDataList = () => import('../views/data/ChannelDataList')
const ResourcesDataList = () => import('../views/data/ResourcesDataList')
//高级设置
const ProSetting = () => import('../views/activity/pro_setting/ProSetting')

//推荐资源数据
const RecommendSourcesData = () => import('../views/activity/childComps/RecommendSourcesData')
// 作品审核
const WorksActivityList = () => import('../views/activity/collect_work/WorksActivityList')
const CollectWorkList = () => import('../views/activity/collect_work/CollectWorkList')
// 作品推选
const ActWorksElectList = () => import('../views/activity/collect_work/elect_system/ActWorksElectList')
const ActElectWork = () => import('../views/activity/collect_work/elect_system/ActElectWork')
const OrgElectList = () => import('../views/activity/collect_work/elect_system/OrgElectList')
const WorksElectList = () => import('../views/activity/collect_work/elect_system/WorksElectList')

// 作品下载
const ActWorksDownloadList = () => import('../views/activity/works_download/ActWorksDownloadList')

const NewsList = () => import('../views/news/NewsList')
const AddOrEditNews = () => import('../views/news/childComps/AddOrEditNews')

// 消息系统
const MessageView = () => import('../views/message/MessageView')

// 帮助中心
const HelpCenterList = () => import('../views/help_center/HelpCenterList')

// AI
const AIDraw = () => import('../views/intelligence/AIDraw')

const PasswordSetting = () => import('../views/setup/PasswordSetting')
const Personal = () => import('../views/setup/Personal')
const Customer = () => import('../views/setup/Customer')

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/home'
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { title: '登录' }
    },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
                redirect: '/homepage',
                children: [
                    {
                        path: '/homepage',
                        name: 'HomePage',
                        component: HomePage,
                        meta: { title: '首页' }
                    },
                    {
                        path: '/organ_information',
                        name: 'OrganInformation',
                        component: OrganInformation,
                        meta: { title: '机构信息' }
                    },
                    {
                        path: '/organ_administrator',
                        name: 'OrganAdministrator',
                        component: OrganAdministrator,
                        meta: { title: '机构管理员' }
                    },
                    {
                        path: '/password_setting',
                        name: 'PasswordSetting',
                        component: PasswordSetting,
                        meta: { title: '账号设置' }
                    },
                    {
                        path: '/organ_list',
                        name: 'OrganList',
                        component: OrganList,
                        meta: { title: '机构列表' }
                    },
                    {
                        path: '/personal',
                        name: 'Personal',
                        component: Personal,
                        meta: { title: '个人资料' }
                    },
                    {
                        path: '/customer',
                        name: 'Customer',
                        component: Customer,
                        meta: { title: '帮助与客服' }
                    },
                    {
                        path: '/activity_type',
                        name: 'ActivityType',
                        component: ActivityType,
                        meta: { title: '发布活动' }
                    },
                    {
                        path: '/activity/create/:type',
                        name: 'ActivityCreate',
                        component: ActivityConstruction,
                        meta: { title: '活动创建' }
                    },
                    {
                        path: '/activity/modify/:type/:id',
                        name: 'ActivityModify',
                        component: ActivityConstruction,
                        meta: { title: '活动修改' }
                    },
                    {
                        path: '/activity/custom_made_create',
                        name: 'CustomMadeCreate',
                        component: CustomMadeCreate,
                        meta: { title: '定制活动' }
                    },
                    {
                        path: '/resources_data_list',
                        name: 'ResourcesDataList',
                        component: ResourcesDataList,
                        meta: { title: '资源数据' }
                    },
                    {
                        path: '/channel_data_list',
                        name: 'ChannelDataList',
                        component: ChannelDataList,
                        meta: { title: '渠道数据' }
                    },
                    {
                        path: '/user_data_list',
                        name: 'UserDataList',
                        component: UserDataList,
                        meta: { title: '用户数据' }
                    },
                    {
                        path: '/activity_data_list',
                        name: 'ActivityDataList',
                        component: ActivityDataList,
                        meta: { title: '活动数据' }
                    },
                    {
                        path: '/enroll_data_list',
                        name: 'EnrollDataList',
                        component: EnrollDataList,
                        meta: { title: '报名数据' }
                    },
                    {
                        path: '/qa_act_enroll_data_list',
                        name: 'QaActEnrollDataList',
                        component: QaActEnrollDataList,
                        meta: { title: '答题活动报名数据' }
                    },
                    {
                        path: '/accessing_data_list',
                        name: 'AccessingDataList',
                        component: AccessingDataList,
                        meta: { title: '活动访问数据' }
                    },
                    {
                        path: '/works_data_list',
                        name: 'WorksDataList',
                        component: WorksDataList,
                        meta: { title: '作品数据' }
                    },
                    {
                        path: '/act_org_data_list',
                        name: 'ActOrgDataList',
                        component: ActOrgDataList,
                        meta: { title: '机构报名数据' }
                    },
                    {
                        path: '/clock_record_list',
                        name: 'ClockRecordList',
                        component: ClockRecordList,
                        meta: { title: '打卡数据' }
                    },
                    {
                        path: '/look_clock_record',
                        name: 'LookClockRecord',
                        component: LookClockRecord,
                        meta: { title: '查看完整打卡数据' }
                    },
                    {
                        path: '/vote_data_list',
                        name: 'VoteDataList',
                        component: VoteDataList,
                        meta: { title: '投票数据' }
                    },
                    {
                        path: '/qa_data_list',
                        name: 'QaDataList',
                        component: QaDataList,
                        meta: { title: '问卷数据' }
                    },
                    {
                        path: '/answer_data_list',
                        name: 'AnswerDataList',
                        component: AnswerDataList,
                        meta: { title: '答卷数据' }
                    },
                    {
                        path: '/qa_detail/:id',
                        name: 'QaDetail',
                        component: QaDetail,
                        meta: { title: '答卷详情' }
                    },
                    {
                        path: '/subjective_grade/:id',
                        name: 'SubjectiveGrade',
                        component: SubjectiveGrade,
                        meta: { title: '主观题评分' }
                    },
                    {
                        path: '/works_data_list/collect_work_detail',
                        name: 'CollectWorkDetail',
                        component: CollectWorkDetail,
                        meta: { title: '作品详情' }
                    },
                    {
                        path: '/recommend_sources_data',
                        name: 'RecommendSourcesData',
                        component: RecommendSourcesData,
                        meta: { title: '推荐资源数据统计' }
                    },
                    {
                        path: '/activity/pro_setting',
                        name: 'ProSetting',
                        component: ProSetting,
                        meta: { title: '高级设置' }
                    },
                    {
                        path: '/activity/list',
                        name: 'ActivityList',
                        component: ActivityList,
                        meta: { title: '本机构的活动' }
                    },
                    {
                        path: '/activity_join/list',
                        name: 'ActivityJoinList',
                        component: ActivityJoinList,
                        meta: { title: '参与组织的活动' }
                    },
                    {
                        path: '/works_activity_list',
                        name: 'WorksActivityList',
                        component: WorksActivityList,
                        meta: { title: '征集类活动列表' }
                    },
                    {
                        path: '/works_activity_list/collect_work_list',
                        name: 'CollectWorkList',
                        component: CollectWorkList,
                        meta: { title: '征集类活动作品审核列表' }
                    },
                    {
                        path: '/act_works_elect_list',
                        name: 'ActWorksElectList',
                        component: ActWorksElectList,
                        meta: { title: '征集类活动作品推选列表' }
                    },
                    {
                        path: '/act_works_elect_list/act_elect_work',
                        name: 'ActElectWork',
                        component: ActElectWork,
                        meta: { title: '征集类活动作品推选' }
                    },
                    {
                        path: '/act_works_elect_list/org_elect_list',
                        name: 'OrgElectList',
                        component: OrgElectList,
                        meta: { title: '征集类活动单位推选情况' }
                    },
                    {
                        path: '/act_works_elect_list/works_elect_list',
                        name: 'WorksElectList',
                        component: WorksElectList,
                        meta: { title: '征集类活动作品推选情况' }
                    },
                    {
                        path: '/act_works_download_list',
                        name: 'ActWorksDownloadList',
                        component: ActWorksDownloadList,
                        meta: { title: '活动作品下载列表' }
                    },
                    {
                        path: '/message',
                        name: 'MessageView',
                        component: MessageView,
                        meta: { title: '消息系统' }
                    },
                    {
                        path: '/news_list',
                        name: 'NewsList',
                        component: NewsList,
                        meta: { title: '资讯管理' }
                    },
                    {
                        path: '/news_list/addoredit_news',
                        name: 'AddOrEditNews',
                        component: AddOrEditNews,
                        meta: { title: '添加/修改资讯' }
                    },
                    {
                        path: '/help_center_list',
                        name: 'HelpCenterList',
                        component: HelpCenterList,
                        meta: { title: '帮助中心' }
                    },
                    {
                        path: '/org_exclusive',
                        name: 'OrgExclusive',
                        component: OrgExclusive,
                        meta: { title: '机构专属页' }
                    },
                    {
                        path: '/course_resources_list',
                        name: 'CourseResourcesList',
                        component: CourseResourcesList,
                        meta: { title: '课程资源' }
                    },
                    {
                        path: '/addoredit_course_resources',
                        name: 'AddOrEditResources',
                        component: AddOrEditResources,
                        meta: { title: '添加/编辑课程资源' }
                    },
                    {
                        path: '/patch_course_resources',
                        name: 'PatchImportResources',
                        component: PatchImportResources,
                        meta: { title: '批量导入课程资源' }
                    },
                    {
                        path: '/org_rank_setting',
                        name: 'OrgRankSetting',
                        component: OrgRankSetting,
                        meta: { title: '机构专属页排行榜配置' }
                    }
                ]
            },
            {
                path: '/organ_apply',
                name: 'OrganApply',
                component: OrganApply,
                meta: { title: '机构用户信息登记表' }
            },
            {
                path: '/add_organ',
                name: 'AddOrgan',
                component: AddOrgan,
                meta: { title: '机构用户信息登记表' }
            },
            {
                path: '/ai_draw',
                name: 'AIDraw',
                component: AIDraw,
                meta: { title: 'AI绘图' }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

//挂载路由导航守卫
router.beforeEach((to, form, next) => {
    // let routerList = ['/homepage','/organ_information','/organ_administrator','/activity_type', '/activity/list', '/activity_join/list', '/sys_message', '/password_setting','/organ_list','/personal','/customer']
    // routerList.map(item => {
    //   if(item == to.path) {
    //     VueCookies.set('activePath',to.path)
    //     // 为vuex里的菜单路由地址重新赋值
    //     store.commit("changeActivePath", to.path);
    //   }
    // })
    // 获取token
    const tokenStr = VueCookies.get('token')
    // 如果没有登录则关闭websocket
    if (websock && !tokenStr && to.path == '/login') {
        closeSock('logout')
    }
    // 统一认证返回的时候进行判断，重定向
    if (to.path == '/uni_web_login') return next({ path: '/login', query: to.query })

    // 如果要访问的是登录页则直接放行
    if (to.path == '/login' || to.path == '/organ_apply') return next()
    // 如果没有token则代表当前尚未登录，强制跳转到登录页
    if (!tokenStr) return next({ path: '/login', query: to.query })

    // 获取用户信息
    const userInfo = VueCookies.get('userInfo')
    // 如果用户信息没有机构则强制跳转到机构申请表
    if (!userInfo.webOrgId) return next('/organ_apply')
    // 如果没有被强制跳转到登录页，则放行到目标页
    next()
})

export default router
