import Vue from 'vue'
import Vuex from 'vuex'
// vue-cookies配置
import VueCookies from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        feedbackDialog: false, // 意见反馈弹窗状态
        activePath: VueCookies.get('activePath') || '',
        userInfo: VueCookies.get('userInfo') ? VueCookies.get('userInfo') : {},
        unReadMessageCnt: null, // 未读消息数量
        sysMessageList: [], // 系统消息列表
        chatMessageList: {}, // 互动消息列表
        chatPersonObj: {}, // 聊天负责人
        isCollapse: false, // 菜单折叠状态
        showPopover: true, // 悬浮菜单显隐
        showAIDrawer: false, // 智能问答窗口显隐
        aiMessageData: [], // ai问答消息数据
        aiChatId: VueCookies.get('aiChatId') || '', // 当前ai对话的会话ID
        activityAiChatObj: {}, // ai帮写功能，如简易版使用AI写活动首页展示内容
        showOrgExclusion: false, //是否显示机构专属页
        computedTable: false //筛选之后需要重新刷新表格布局
    },
    getters: {},
    mutations: {
        // 更新ai帮写功能对象
        renewActivityAiChatObj(state, data) {
            state.activityAiChatObj = data
        },
        // 更新智能问答窗口显隐状态
        renewShowAIDrawer(state, status) {
            state.showAIDrawer = status
        },
        // 更新菜单折叠状态
        renewCollapse(state, status) {
            state.showPopover = !status
            state.isCollapse = status
        },
        // 更新悬浮菜单显隐
        renewShowPopover(state, status) {
            state.showPopover = status
        },
        // 修改意见反馈弹窗状态
        renewFeedbackDialog(state, data) {
            state.feedbackDialog = data
        },
        // 修改左侧菜单栏路径
        changeActivePath(state, path) {
            state.activePath = path
        },
        // 更新用户信息
        renewUserInfo(state, data) {
            state.userInfo = data
        },
        // 更新未读消息数量
        renewUnReadMessageCnt(state, data) {
            state.unReadMessageCnt = data
        },
        // 更新未读消息数据
        renewSysMessageList(state, list) {
            state.sysMessageList = list
        },
        // 更新AI问答消息数据
        renewAiMessageData(state, data) {
            state.aiMessageData = data
        },
        // 更新AI问答会话ID
        renewAiChatId(state, id) {
            state.aiChatId = id
        },
        // 更新聊天负责人数据
        renewChatPersonObj(state, obj) {
            state.chatPersonObj = obj
        },
        // 更新未读互动消息数据
        renewChatMessageList(state, list) {
            state.chatMessageList = list
        },
        // 更新机构专属页
        renewShowOrgExclusion(state, data) {
            state.showOrgExclusion = data
        },
        //  更新是否需要重新刷新表格布局
        renewComputedTable(state, data) {
            state.computedTable = data
        }
    },
    actions: {},
    modules: {}
})
